import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import * as ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'query-string';
import * as NetworkStore from 'stores/Network';

import PageMetaData from 'components/PageMetaData';

@withRouter
@connect((state) => ({network: state.network}), NetworkStore.actionCreators)
export default class Prop65Acknowledgement extends React.Component {
    static propTypes = {
        network: NetworkStore.StateShape,
        ...NetworkStore.StateShape,
        ...ReactRouterPropTypes,
    };

    constructor(props) {
        super(props);
        this.state = {

            step: '',

        };

    }

    componentDidMount() {
        
        const {location: {search}} = this.props;
        const query = queryString.parse(search);
        const lineIds = query.refs;
        const requestor = query.rqs;
        axios.post(`/ShoppingService/api/v1/account/ConfirmAcknowledgement/${lineIds}/${requestor}`).then(x => {

            if (x.data.success === true)

                this.setState({step: 1});

            else
                this.setState({step: 2});
        });
    }

    render() {
        const {step} = this.state;

        return (<div >
            <PageMetaData
                title="Prop65Acknowledgement"
                pageType="other"
                trackAnalytics={false} />

            {step === 1 ?
                <div >
                    <h1 style={{marginBottom: '25px', fontWeight: 'bold', width: '800px', marginTop: '60px'}}>Thanks for Prop 65 Acknowledgement </h1>

                    <p style={{fontSize: '15px'}}>

                        In accordance with California Proposition 65 regulations, if I am a reseller I hereby acknowledge the Reseller Letter and my corresponding reseller oblications located <a data-ea-exit-link="Supplier Prop 65" href="https://www1.partssource.com/hubfs/Supplier ePF Documentation/Prop 65 Letter 061722.pdf" target={'_blank'} rel="noreferrer">here</a>, and represent that I am an authorized agent for my organization, designated to receive and acknowledge such notices.

                    </p>

                </div>
                : ''}
            {step === 2 ?

                <div>
                    <h1 style={{marginBottom: '25px', marginTop: '60px'}}>Something went wrong... Please try again.</h1>

                </div>
                : ''}
        </div>);
    }
}

